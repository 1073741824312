import { Injectable } from '@angular/core';
declare const Waypoint: any;

import * as $ from 'jquery';

@Injectable({providedIn: 'root'})

export class PageService {
    constructor() { }

    animateHero() {
        if (($('#fh5co-hero') as any).length > 0) {
            let wp = new Waypoint({
                element: document.getElementById('fh5co-hero'),
                handler: (direction) => {
                    if (direction === 'down' && !$(this).hasClass('animated')) {

                        if (!$('.intro-animate-1').hasClass('fadeInUp animated')) {
                            setTimeout(function () {
                                $('.intro-animate-1').addClass('fadeInUp animated');
                            }, 100);
                        }
                        if (!$('.intro-animate-2').hasClass('fadeInUp animated')) {
                            setTimeout(function () {
                                $('.intro-animate-2').addClass('fadeInUp animated');
                            }, 400);
                        }
                        if (!$('.intro-animate-3').hasClass('fadeInUp animated')) {
                            setTimeout(function () {
                                $('.intro-animate-3').addClass('fadeInUp animated');
                            }, 700);
                        }
                        if (!$('.intro-animate-4').hasClass('fadeInUp animated')) {
                            setTimeout(function () {
                                $('.intro-animate-4').addClass('fadeInUp animated');
                            }, 700);
                        }


                    }
                },
                offset: '75%'
            });
        }
    }

    animateFeatures() {
        if ($('#fh5co-features').length > 0) {
            let clientAnimate = function () {
                $('#fh5co-features .to-animate').each(function (k) {

                    let el = $(this);

                    setTimeout(function () {
                        el.addClass('fadeIn animated');
                    }, k * 200, 'easeInOutExpo');

                });
            };

            let wp = new Waypoint({
                element: document.getElementById('fh5co-features'),
                handler: (direction) => {
                    if (direction === 'down' && !$(this).hasClass('animated')) {
                        setTimeout(clientAnimate, 100);
                    }
                },
                offset: '75%'
            });
        }
    }

    animateFeatures2() {
        if ($('#fh5co-features-2').length > 0) {
            let features2Animate = function () {
                if ($('#fh5co-features-2').length > 0) {
                    $('#fh5co-features-2 .to-animate').each(function (k) {

                        var el = $(this);

                        setTimeout(function () {
                            el.addClass('fadeIn animated');
                        }, k * 200, 'easeInOutExpo');

                    });
                }
            };


            let wp = new Waypoint({
                element: document.getElementById('fh5co-features-2'),
                handler: (direction) => {
                    if (direction === 'down' && !$(this).hasClass('animated')) {


                        setTimeout(features2Animate, 100);

                        setTimeout(function () {
                            $('.features-2-animate-2').addClass('fadeInUp animated');
                        }, 800);

                        setTimeout(function () {
                            $('.features-2-animate-3').addClass('fadeInRight animated');
                            $('.features-2-animate-5').addClass('fadeInLeft animated');
                        }, 1200);
                        setTimeout(function () {
                            $('.features-2-animate-4').addClass('fadeInRight animated');
                            $('.features-2-animate-6').addClass('fadeInLeft animated');
                        }, 1400);

                    }
                },
                offset: '75%'
            });
        }
    }

    animateFeatures3() {
        if ($('#fh5co-features-3').length > 0) {
            let features3Animate = function () {
                if ($('#fh5co-features-3').length > 0) {
                    $('#fh5co-features-3 .to-animate').each(function (k) {

                        let el = $(this);

                        setTimeout(function () {
                            el.addClass('fadeIn animated');
                        }, k * 200, 'easeInOutExpo');

                    });
                }
            };


            let wp = new Waypoint({
                element: document.getElementById('fh5co-features-3'),
                handler: (direction) => {
                    if (direction === 'down' && !$(this).hasClass('animated')) {
                        setTimeout(function () {
                            $('.features3-animate-1').addClass('fadeIn animated');
                        }, 200);

                        setTimeout(function () {
                            $('.features3-animate-2').addClass('fadeIn animated');
                        }, 400);

                        setTimeout(function () {
                            $('.features3-animate-3').addClass('fadeIn animated');
                        }, 600);

                        setTimeout(features3Animate, 800);
                    }
                },
                offset: '75%'
            });
        }
    }

    animateProducts() {
        if ($('#fh5co-products').length > 0) {
            let productsAnimate = function () {
                if ($('#fh5co-products').length > 0) {
                    $('#fh5co-products .to-animate').each(function (k) {

                        let el = $(this);

                        setTimeout(function () {
                            el.addClass('fadeIn animated');
                        }, k * 200, 'easeInOutExpo');

                    });
                }
            };


            let wp = new Waypoint({
                element: document.getElementById('fh5co-products'),
                handler: (direction) => {
                    if (direction === 'down' && !$(this).hasClass('animated')) {
                        setTimeout(function () {
                            $('.product-animate-1').addClass('fadeIn animated');
                        }, 200);

                        setTimeout(function () {
                            $('.product-animate-2').addClass('fadeIn animated');
                        }, 400);

                        setTimeout(productsAnimate, 800);
                    }
                },
                offset: '75%'
            });
        }
    }

    animateCta() {
        if ($('#fh5co-cta').length > 0) {
            let ctaAnimate = function () {
                if ($('#fh5co-cta').length > 0) {
                    $('#fh5co-cta .to-animate').each(function (k) {

                        let el = $(this);

                        setTimeout(function () {
                            el.addClass('fadeIn animated');
                        }, k * 200, 'easeInOutExpo');

                    });
                }
            };


            let wp = new Waypoint({
                element: document.getElementById('fh5co-cta'),
                handler: (direction) => {
                    if (direction === 'down' && !$(this).hasClass('animated')) {
                        setTimeout(ctaAnimate, 100);
                    }
                },
                offset: '75%'
            });
        }
    }

    addScroll() {
        new Waypoint({
            element: document.getElementById('fh5co-main'),
            handler: (direction) => {
                if (direction === 'down') {
                    $('body').addClass('scrolled');
                    $('.navbar-brand .fs-logo').addClass('black');
                } else if (direction === 'up') {
                    $('body').removeClass('scrolled');
                    $('.navbar-brand .fs-logo').removeClass('black');
                }
            }
        }, {
            offset: '20px'
        });
    }

    addNav() {
        var navActive = function (section) {
            var el = $('#fh5co-offcanvass > ul');
            el.find('li').removeClass('active');
            el.each(function () {
                $(this).find('a[data-nav-section="' + section + '"]').closest('li').addClass('active');
            });
        };

        
        let sectionElem: any = $('div[data-section]');
        for (const elem of sectionElem) {
            new Waypoint({
                element: elem,
                handler: (direction) => {
                    if (direction === 'down') {
                        navActive($(elem).data('section'));
                    }
                }
            }, {
                offset: '150px'
            });
    
            new Waypoint({
                element: elem,
                handler: (direction) => {
                    if (direction === 'up') {
                        navActive($(elem).data('section'));
                    }
                }
            }, {
                offset: function () {
                    return -$(elem).height() + 155;
                }
            });
        }
    }

    scrollIntoView(element) {
        $('html, body').animate({
            scrollTop: $('[data-section="' + element + '"]').offset().top
        }, 1000);
    }

    scrollToTop() {
        $('html, body').scrollTop(0);
    }
    
}