import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { FsLogoComponent } from './shared/fs-logo/fs-logo.component';
import { PsgComponent } from './pages/psg/psg.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { PageService } from './_services/page.service';
import { APIService } from './_services/api.service';
import { EmenuComponent } from './pages/emenu/emenu.component';


@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		HomeComponent,
		FsLogoComponent,
		PsgComponent,
		EmenuComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		CarouselModule
	],
	providers: [
		PageService,
		APIService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
