import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class APIService {
    constructor() { }

    mailPath() {
        return `mail/sendmail.php`;
    }
    
}