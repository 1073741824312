import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fs-logo',
  templateUrl: './fs-logo.component.html',
  styleUrls: ['./fs-logo.component.scss']
})
export class FsLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
