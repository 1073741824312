import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PageService } from 'src/app/_services/page.service';

declare const Waypoint: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	constructor(
		public _page: PageService,
		private router: Router
	) {
		this.router.events.subscribe(d => {
			if (d instanceof NavigationEnd) {
				this._page.scrollToTop();
				this.currentPage = d.url;
				if (d.url === '/') {
					this.currentMenu = this.homeMenu;
				} else if (d.url === '/psg') {
					this.currentMenu = this.psgMenu;
				} else if (d.url === '/emenu') {
					this.currentMenu = this.emenuMenu;
				}
			}
		})
	}

	currentMenu = [];
	currentPage = '/';

	homeMenu = [
		{
			title: 'Home',
			link: 'home'
		},
		{
			title: 'About',
			link: 'about'
		},
		{
			title: 'Flex-HQ',
			link: 'product'
		},
		{
			title: 'Solutions',
			link: 'solutions'
		},
		{
			title: 'Clients',
			link: 'clients'
		},
		{
			title: 'Achievements',
			link: 'achievements'
		},
		{
			title: 'Contact',
			link: 'contact'
		}
	]

	psgMenu = [
		{
			title: 'Home',
			link: 'home'
		},
		{
			title: 'Overview',
			link: 'overview'
		},
		{
			title: 'Packages',
			link: 'packages'
		},
		{
			title: 'Solutions',
			link: 'solutions'
		},
		{
			title: 'Request Now',
			link: 'writein'
		},
	]

	emenuMenu = [
		{
			title: 'Intro',
			link: 'intro'
		},
		{
			title: 'Ordering Process',
			link: 'ordering-process'
		},
		{
			title: 'Features',
			link: 'package-details'
		},
		{
			title: 'Trial',
			link: 'start-trial'
		},
		{
			title: 'Why work with us?',
			link: 'why-work'
		},
		{
			title: 'Contact',
			link: 'contact'
		}
	]

	ngOnInit() {
	}

}
