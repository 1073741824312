import { Component, OnInit } from '@angular/core';

import { CAROUSEL_OPTIONS } from '../../shared/constants';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PageService } from 'src/app/_services/page.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { APIService } from 'src/app/_services/api.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	constructor(
		private http: HttpClient,
		public _page: PageService,
		private _api: APIService
	) { }

	pageLoaded = false;
	carouselOptions: OwlOptions = CAROUSEL_OPTIONS;

	mailForm = new FormGroup({
		email: new FormControl(null, [Validators.required, Validators.email]),
		name: new FormControl(null, Validators.required),
		contact: new FormControl(null, Validators.required),
		subject: new FormControl(null),
		message: new FormControl(null, Validators.required)
	});
	mailResult;

	ngOnInit() {
		this._page.animateHero();
		this._page.animateFeatures();
		this._page.animateFeatures2();
		this._page.animateFeatures3();
		this._page.animateProducts();
		this._page.animateCta();
		this._page.addScroll();
		this._page.addNav();
	}

	ngAfterViewInit() {
		setTimeout(() => {			
			this.pageLoaded = true;
		}, 1000);
	}

	submitMail() {
		this.mailResult = null;
		if (!this.mailForm.invalid) {
			
			let subject = `Feedback from ${this.mailForm.get('name').value} at flex-solver.com.sg`;
			if (this.mailForm.get('subject').value) {
				subject = this.mailForm.get('subject').value;
			}
			
			let content = `Name: ${this.mailForm.get('name').value}\r\nContact Number: ${this.mailForm.get('contact').value}\r\nEmail: ${this.mailForm.get('email').value}\r\nMessage: ${this.mailForm.get('message').value}`
			
			const body = {
				recipient: 'jerry@flex-solver.com.sg',
				subject,
				content,
				name: this.mailForm.get('name').value,
				email: this.mailForm.get('email').value
			}
			this.http.post(this._api.mailPath(), body).subscribe((response: any) => {
				this.mailResult = response;
				this.mailForm.reset();
			}, error => {
				this.mailResult = error;
			})
		} else {
			this.mailResult = 'Please fill up the form before submitting.';
		}
	}

	

}
