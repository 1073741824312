import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PsgComponent } from './pages/psg/psg.component';
import { EmenuComponent } from './pages/emenu/emenu.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'emenu',
		component: EmenuComponent
	},
	{
		path: 'psg',
		component: PsgComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
