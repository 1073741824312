export const CAROUSEL_OPTIONS = {
    items: 1,
    margin: 100,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 500,
    responsive: {
        0: {
            items: 1,
        },
        480: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1000: {
            items: 1,
        }
    },
    navText: [
        "<i class='icon-arrow-left owl-direction'></i>",
        "<i class='icon-arrow-right owl-direction'></i>"
    ]
};