import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/_services/page.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { APIService } from 'src/app/_services/api.service';

@Component({
	selector: 'app-psg',
	templateUrl: './psg.component.html',
	styleUrls: ['./psg.component.scss']
})
export class PsgComponent implements OnInit {

	constructor(
		private _page: PageService,
		private _api: APIService,
		private http: HttpClient
	) { }

	mailForm = new FormGroup({
		email: new FormControl(null, [Validators.required, Validators.email])
	});
	mailResult;

	ngOnInit() {
		this._page.addScroll();
		this._page.addNav();
		this._page.animateHero();
		this._page.animateFeatures();
		this._page.animateFeatures2();
	}

	submitMail() {
		this.mailResult = null;
		if (!this.mailForm.invalid) {

			let subject = `PSG Request from ${this.mailForm.get('email').value} at flex-solver.com.sg`;

			let content = `Request from: ${this.mailForm.get('email').value}\r\nThere is a PSG request from ${this.mailForm.get('email').value}`;

			const body = {
				recipient: 'jerry@flex-solver.com.sg',
				content,
				subject,
				email: this.mailForm.get('email').value
			}
			this.http.post(this._api.mailPath(), body).subscribe((response: any) => {
				this.mailResult = response;
				this.mailForm.reset();
			}, error => {
				this.mailResult = error;
			})
		} else {
			this.mailResult = 'Please fill up the form before submitting.';
		}
	}

}
